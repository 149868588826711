import Vue from 'vue'
// import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

import moment from "moment";
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'

export default {
    components: {
        vSelect,
        Datepicker,
    },
    data: function() {
        return {
            base_url: Vue.BASE_URL,
            pageTitle: "Tableau de bord",
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            filtreDate: {
                dateDebut:"",
                dateFin:""
            },
            date_exercice: {
                date_debut: "",
                date_fin: ""
            },
            afficherToutExercice: "checked",
            dashboard: {
                recette_total: "",
                recette_espece: "",
                depense_espece: "",
                recette_banque_detail: [],
                depense_banque_detail: [],
                recette_banque: "",
                depense_banque: "",
                chiffre_affaire: "",
            },
            url_pdf_devis: ""
        }
    },
    methods: {

        
        getDateExercice() {
            var that = this;
            axios.get(that.base_url+"/exercice/get/1").then(response => {
                console.log(response.data);
                that.date_exercice.date_debut = moment(response.data[0].date_debut).format('DD/MM/YYYY');
                that.date_exercice.date_fin = moment(response.data[0].date_fin).format('DD/MM/YYYY');
                this.changeExercice();
            });

        },
        changeExercice() {
            if(this.$refs.afficherToutExercice.checked == true) {
                this.filtreDate.dateDebut = this.date_exercice.date_debut;
                this.filtreDate.dateFin = this.date_exercice.date_fin;
            } else {
                this.filtreDate.dateDebut = moment().format('DD/MM/YYYY');
                this.filtreDate.dateFin = moment().format('DD/MM/YYYY');
            }
        },
        getDashboardData() {
            var that = this;
            axios.get(that.base_url+"/appparams/gettb/?date_debut="+this.filtreDate.dateDebut+"&date_fin="+this.filtreDate.dateFin).then(response => {
                this.dashboard = response.data;
            });

            this.url_pdf_devis = that.base_url+"/appparams/printgl1/?date_debut="+this.filtreDate.dateDebut+"&date_fin="+this.filtreDate.dateFin;

        },

    },
    computed: {
        
    },
    watch: {
        "filtreDate.dateDebut": function() {
            this.getDashboardData();
        },
        "filtreDate.dateFin": function() {
            this.getDashboardData();
        }
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);
        this.getDateExercice();
        
        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;
    },
    mounted: function() {

        this.getDashboardData();

    }
}